import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

// import ReactGA4 from 'react-ga4';
// import ReactGA from 'react-ga';

import utils from '../helpers/utils';
import userUtils from '../helpers/userUtils';

export default function usePageViews() {
	const location = useLocation();

	useEffect(() => {
		setPageTitle();

		// if (!(window as any)['GA_INITIALIZED']) {
		//     ReactGA4.initialize('G-PPKJCEKLT1');
		//     (window as any)['GA_INITIALIZED'] = true;
		// }

		// ReactGA.set({ page: location.pathname });
		// ReactGA.pageview(location.pathname + location.search);

		if (utils.isSuperAdmin() || userUtils.debugMode()) {
			console.log('Logging page view:', location.pathname + location.search);
		}
	}, [location]);

	return location;
}

export const setPageTitle = () => {
	let title = window.location.pathname || '';
	console.debug(title);

	let formatter: RouteTitleFormatter | undefined;
	const queryParams = getQueryParams();
	let params: Record<string, string> = {};

	// @ts-ignore Find the formatter function for the current route path
	for (const [path, formatFunction] of Object.entries(routeTitleFormatters)) {
		const match = matchPath<{ [key: string]: string }>(title, {
			path,
			exact: true,
		});

		if (match) {
			formatter = formatFunction;
			params = match.params;
			break;
		}
	}

	let uid = params.uid || utils.uid;
	if (formatter) {
		title = formatter(params || {}, queryParams || {});
	} else title = utils.capitalize(title.replace(/\//g, ' '));
	const invalidUIDs = ['1', '2'];
	const isValidUID = !invalidUIDs.includes(uid);

	document.title = `${userUtils.isAdmin() && isValidUID ? `${uid} ` : ''} ${title} ${userUtils.isAdmin() ? `` : ' | AIQ'}`;
};

const templateHandler = ({ page = '' }, { recipe = ' ' }) => {
	if (page === 'co-marketing') {
		return 'Co-Marketing Templates';
	}
	if (page === 'builder') {
		return `Template Builder ${recipe ? `(${recipe})` : ''}`;
	}
	return 'Marketing Templates';
};

type RouteTitleFormatter = (params: { [key: string]: string }, query: { [key: string]: string }) => string;
const routeTitleFormatters: Record<string, RouteTitleFormatter> = {
	'/login': () => `Login`,
	'/login/:sso': () => `Login`,
	'/login/:uid/:token': () => `Login`,
	'/swagger': () => `Swagger`,
	'/wallet/:uid/:contactID/:tab': () => `Web Wallet`,
	'/wallet/:uid/:contactID': () => `Web Wallet`,
	'/wallet/:uid': () => `Web Wallet`,
	'/wallet/': () => `Web Wallet`,
	'/planner/:uid': () => `Planner`,
	'/product/:uid/:productID/:batchID/:collectibleID': () => `Product Landing Page`,
	'/walletsearch/:uid': () => `Wallet Search`,
	'/walletsearch/:uid/:phone': () => `Wallet Search`,
	'/walletsearch/byID/:uid/:contactID': () => `Wallet Search`,
	'/review/:uid/:contactID': () => `Review Us`,
	'/review/:uid': () => `Review Us`,
	'/redeemLoyalty/:uid/:contactID/:cmpID': () => `Redeem Loyalty`,
	'/redeemDiscount/:uid/:storeID/:templateID/:key': () => `Redeem Discount`,
	'/redeemDiscount/:uid/:templateID/:key': () => `Redeem Discount`,
	'/join/c/:uid/:formID': () => `Signup Form`,
	'/joins/c/:uid/:formID': () => `Signup Form`,
	'/joinMembers/:uid': () => `Signup Form`,
	'/joinLoyalty/:uid': () => `Signup Form`,
	'/trackingPreferences/:uid': () => `Tracking Preferences`,
	'/trackingPreferences/:step/:uid/:reqKey': () => `Tracking Preferences`,
	'/forgotPassword': () => `Forgot Password`,
	'/resetPassword/:token': () => `Forgot Password`,
	'/newLogin/:token': () => `Forgot Password`,
	'/logout': () => `Logout`,
	'/signupflow/:token': () => `Invite Signup Flow`,
	'/signupflow/:step/:uid/:token': () => `Invite Signup Flow`,
	'/signupflow': () => `Invite Signup Flow`,
	'/signupflow/:step/:uid': () => `Invite Signup Flow`,
	'/globalStyles': () => `Global Styles`,
	'/admin': () => `Enterprise Home`,
	'/search': () => `Search Accounts`,
	'/agency/:uid': () => `Agency`,
	'/dashboard/:uid': () => `Reports`,
	'/storeDesign/:uid': () => `Store Design`,
	'/storeDesign/:uid/:storeID': () => `Store Design`,
	'/receiptLookup': () => `Receipt Lookup`,
	'/receiptLookup/:uid': () => `Receipt Lookup`,
	'/fileUploads/:uid': () => `File Uploads`,
	'/userAlerts/:uid': () => `User Alerts`,
	'/domainHealth': () => `Domain Health`,
	'/duplicateFields': () => `Duplicate Fields`,
	'/admin/accounts/renewals': () => `Account Renewals`,
	'/admin/accounts/renewals/:tab': () => `Account Renewals`,
	'/accountHealth': () => `Account Health`,
	'/accountHealth/:tab': () => `Account Health`,
	'/greenbits': () => `Greenbits`,
	'/loginLookup': () => `Login Lookup`,
	'/sources/:uid': () => `Sources`,
	'/audiences/builder/:uid': () => `Audience Builder`,
	'/audiences/builder/:uid/:audienceID': ({ audienceID = '' }) => `Audience Builder (${audienceID})`,
	'/campaign/builder/:uid': () => `Campaign Builder`,
	'/campaign/builder/:uid/:campaignID': ({ campaignID = '' }) => `Campaign Builder (${campaignID})`,
	'/audiences/manage/:uid': () => `Audiences`,
	'/audiences/manage/:uid/:groupId': () => `Audiences`,
	'/discounts/manage/:uid': () => `Discounts`,
	'/discounts/manage/:uid/:groupId': () => `Discounts`,
	'/discount/builder/:uid': () => `Discount Builder`,
	'/discount/builder/:uid/:templateID': ({ templateID = '' }) => `Discount Builder (${templateID})`,
	'/campaigns/manage/:uid': () => `Campaigns`,
	'/campaigns/manage/:uid/:groupId': () => `Campaigns`,
	'/campaigns/reports/:uid': () => `Campaign Reports`,
	// "/reviews/manage/:uid": () => `Reviews`,
	'/settings/:uid': () => `Account Settings`,
	'/signup-forms/:uid': () => `Signup Forms`,
	'/settings/:uid/signup/builder/': () => `Signup Builder`,
	'/settings/:uid/signup/builder/:formID': ({ formID = '' }) => `Signup Builder (${formID})`,
	'/settings/:uid/:tab': ({ tab = '' }) => `Settings (${utils.capitalize(tab)})`,
	'/settings/:uid/:tab/:subTab': ({ tab = '', subTab = '' }) => `Settings (${utils.capitalize(tab)} - ${utils.capitalize(subTab)})`,
	'/surveysreviews/:uid/:page': ({ page }) => `Surveys & Reviews`,
	'/compliance/:uid': () => `Compliance`,
	'/tracking/:uid': () => `Tracking`,
	'/analysisGV/:uid': () => `Analysis GV`,
	'/public/analysis/:uid/:reportID': () => `Retail Insights`,
	'/public/analysis/:uid': () => `Retail Insights`,
	'/scheduled/analysis/:uid/:scheduledID': () => `Retail Insights`,
	'/analysis/:uid': () => `Retail Insights`,
	'/analysis/:uid/:audienceIDs': () => `Retail Insights`,
	'/analysis/:uid/group/:groupID': () => `Retail Insights`,
	'/public/brandAnalysis/:uid/:reportID': () => `Brand Insights`,
	'/scheduled/brandAnalysis/:uid/:scheduledID': () => `Brand Insights`,
	'/brandAnalysis/:uid': () => `Brand Insights`,
	'/brandAnalysis/:uid/:savedID': () => `Brand Insights`,
	'/marketSpotlightV1/:uid': () => `Sales Discovery`,
	'/marketSpotlightV1/:uid/:tab': () => `Sales Discovery`,
	'/marketSpotlight/:uid': () => `Market Analysis`,
	'/scheduledReports/:uid/:tab': () => `Scheduled Reports`,
	'/dataNetwork/:uid': () => `Data Network`,
	'/personas/:uid': () => `Personas`,
	'/missionControl/:uid': () => `Mission Control`,
	'/marketplace/:uid': () => `Marketplace`,
	'/globalschema/:uid': () => `Global Schema`,
	'/billing/add/:uid': () => `Add Billing`,
	'/thankyou/:uid': () => `Thank You`,
	'/user/edit/:uid': () => `User Edit`,
	'/templates/:uid': () => `Marketing Templates`,
	'/templates/:uid/:page': templateHandler,
	'/templates/:uid/:page/:subPage': templateHandler,
	'/mediaLibrary/:uid': () => `Media Library`,
	'/agency': () => `Agency`,
	'/user/edit': () => `User Edit`,
	'/settings': () => `Settings`,
	'/marketplace': () => `Marketplace`,
	'/userAlerts': () => `User Alerts`,
	'/reports/fanbase': () => `Fanbase Reports`,
	'/sources/create': () => `Create Source`,
	'/sources': () => `Sources`,
	'/audiences/manage/': () => `Manage Audiences`,
	'/discounts/manage/': () => `Manage Discounts`,
	'/campaign/builder/': () => `Campaign Builder`,
	'/campaigns/manage/': () => `Manage Campaigns`,
	'/campaigns/reports/': () => `Campaign Reports`,
	// "/reviews/manage/": () => `Manage Reviews`,
	'/settings/': () => `Settings Manager`,
	'/compliance': () => `Compliance`,
	'/media': () => `Media`,
	'/tracking': () => `Tracking`,
	'/email/settings': () => `Email Settings`,
	'/app/settings': () => `App Settings`,
	'/analysisGV': () => `Analysis GV`,
	'/analysis': () => `Retail Insights`,
	'/dataNetwork': () => `Data Network`,
	'/personas': () => `Personas`,
	'/thankyou': () => `Thank You`,
	'/missionControl': () => `Mission Control`,
	'/emailSettings/:uid': () => `Email Settings`,
	'/emailOptOut/:uid': () => `Email Settings`,
	'/aiqcrew/members/:uid': () => `Crew Members`,
	'/aiqcrew/leaderboard/:uid': () => `Crew Leaderboard`,
	'/aiqcrew/goals/:uid': () => `Crew Goals`,
	'/aiqcrew/reports/:uid': () => `Crew Reports`,
	'/aiqcrew/surveys/:uid': () => `Crew Surveys`,
	'/aiqcrew/resources/:uid': () => `Crew Resources`,
	'/aiqcrew': () => `Crew Home`,
	'/ecommerce/:uid': () => `Ecommerce`,
	'/chat/:uid': () => `Chat Inbox`,
};

const getQueryParams = (): Record<string, string> => {
	const queryParams = new URLSearchParams(window.location.search);
	const params: Record<string, string> = {};

	// @ts-ignore
	for (const [key, value] of queryParams.entries()) {
		params[key] = value;
	}

	return params;
};
