import { asyncWrap, getUserSettings, safeWrap, updateUserSettings } from '@/api';
import { EntryMap, Setter } from '@/legacy-types';
import { create } from 'zustand';
import { deleteUserSettings } from '../api/user-settings';
import { clone, isEqual } from 'lodash';
import { getValue, Paths, utils } from '@/helpers';
import { AppSettings, UserSettings } from '@/types';

type Data = EntryMap<Partial<UserSettings>>;
export type UserSettingsEditingState = {
	loading: boolean;
	loaded: boolean;

	selectedStoreID: string;

	globalSettings: Partial<UserSettings>;
	userSettings: Partial<UserSettings>;
	userSettingsMap: Data;
	ogSettingsMap: Data;
};

export type UserSettingsEditingActions = {
	setSelectedStoreID: (storeId: string) => void;
	storeIdOrDefault: (storeId?: string) => string;
	setUserSettings: (userSettings: Setter<Partial<UserSettings>>, storeId?: string) => void;
	setAppSettings: (appSettings: Setter<Partial<AppSettings>>, storeId?: string) => void;
	getUserSettings: (storeId?: string) => Partial<UserSettings>;
	deleteUserSettings: (storeId?: string) => Promise<void>;
	fetchUserSettings: (storeId?: string, uid?: string) => Promise<Partial<UserSettings>>;
	resetAppSettings: () => void;
	saveUserSettings: () => Promise<void>;
	hasLoaded: (storeId?: string) => boolean;
	getWithDefault: <T = string>(paths: Paths<UserSettings>[], defaultValue: T) => T;

	getChanged: () => Partial<UserSettings>[];
	clearChanges: () => void;
};

export type UserSettingsEditingStore = UserSettingsEditingState & UserSettingsEditingActions;

const initialState: UserSettingsEditingState = {
	loaded: false,
	loading: true,
	selectedStoreID: '-1',
	globalSettings: {},
	userSettings: {},
	userSettingsMap: {},
	ogSettingsMap: {},
};

export const useUserSettingsEditingStore = create<UserSettingsEditingStore>((set, get) => ({
	...initialState,

	hasLoaded: (storeId = '-1') => {
		const { userSettingsMap = {} } = get();
		return userSettingsMap[storeId] !== undefined;
	},

	getChanged: () => {
		const { userSettingsMap = {}, ogSettingsMap = {} } = get();
		const storeKeys = new Set([...Object.keys(userSettingsMap), ...Object.keys(ogSettingsMap)]);
		return Array.from(storeKeys)
			.filter((key) => !isEqual(userSettingsMap[key], ogSettingsMap[key]))
			.map((key) => userSettingsMap[key]);
	},

	setSelectedStoreID(storeId) {
		// Changes the store ID, also updates the userSettings to the new store ID
		set({ selectedStoreID: storeId || '-1' });
		const hasLoaded = get().hasLoaded(storeId);
		if (hasLoaded) {
			set({ userSettings: get().getUserSettings(storeId) });
		} else {
			get().fetchUserSettings(storeId);
		}
	},

	storeIdOrDefault(storeId) {
		const useID = storeId || get().selectedStoreID || '-1';
		return `${useID}`;
	},

	getUserSettings(storeId) {
		const storeID = get().storeIdOrDefault(storeId);
		const { userSettingsMap = {} } = get();
		// If settinsg key doesn't exist, then fetch from the global settings
		return userSettingsMap[storeID] ?? {};
	},

	// Set user settings on the userSettingsMap
	setUserSettings(userSettings: Setter<Partial<UserSettings>>, storeId?: string) {
		const currSettings = get().getUserSettings(storeId);
		const newSettings = typeof userSettings === 'function' ? userSettings(currSettings) : userSettings;

		if (newSettings.appSettings) {
			newSettings.appSettings.lastUpdated = Date.now();
		}

		const storeID = get().storeIdOrDefault(storeId);
		const { userSettingsMap = {} } = get();
		userSettingsMap[storeID] = newSettings;
		const newState: Partial<UserSettingsEditingState> = { userSettingsMap };
		if (!storeId || storeID === get().selectedStoreID) {
			newState.userSettings = newSettings ?? {};
		}
		if (storeID === '-1') {
			newState.globalSettings = newSettings ?? {};
		}
		set(newState);
		return newSettings;
	},

	setAppSettings: (appSettings: Setter<Partial<AppSettings>>, storeId?: string) => {
		const currSettings = get().getUserSettings(storeId);
		const newSettings = typeof appSettings === 'function' ? appSettings(currSettings.appSettings ?? {}) : appSettings;
		get().setUserSettings((curr) => ({ ...curr, appSettings: newSettings }), storeId);
	},

	deleteUserSettings(storeId = '') {
		const storeID = get().storeIdOrDefault(storeId);
		return asyncWrap(
			async () => {
				set({ loading: true });
				await deleteUserSettings(storeID);
				const { userSettingsMap = {}, ogSettingsMap = {} } = get();
				delete userSettingsMap[storeID];
				delete ogSettingsMap[storeID];
				set({ userSettingsMap, ogSettingsMap, loading: false });
			},
			{
				errorCallback(error) {
					set({ loading: false });
					throw error;
				},
			},
		);
	},

	fetchUserSettings(storeId = '', uid = utils.uid, merge = false) {
		const storeID = get().storeIdOrDefault(storeId);
		// Always fetch the global settings if they are not already fetched
		if (!get().globalSettings) {
			get().fetchUserSettings('-1');
		}
		return asyncWrap(
			async () => {
				set({ loading: true });
				const userSettings = (await getUserSettings(storeID, { uid, queryParams: { merge } })) ?? {};
				const { userSettingsMap = {}, ogSettingsMap = {} } = get();
				userSettingsMap[storeID] = clone(userSettings);
				ogSettingsMap[storeID] = clone(userSettings);
				const newChanges: Partial<UserSettingsEditingState> = { userSettingsMap, ogSettingsMap, loaded: true, loading: false };
				if (storeID === '-1' || get().selectedStoreID === userSettings.storeID) {
					newChanges.userSettings = userSettings ?? {};
				}
				if (storeID === '-1') {
					newChanges.globalSettings = userSettings ?? {};
				}
				set(newChanges);
				return userSettings;
			},
			{
				errorCallback(error) {
					set({ loading: false });
					throw error;
				},
			},
		) as Promise<Partial<UserSettings>>;
	},

	async saveUserSettings() {
		const { userSettingsMap = {}, ogSettingsMap = {} } = get();

		// If none of the settings have changed, then don't update anything
		if (get().getChanged().length === 0) {
			set({ loading: false });
			return;
		}

		set({ loading: true });

		const newSettingMap: Data = { ...userSettingsMap };
		const errors: string[] = [];

		const combinedKeys = new Set([...Object.keys(userSettingsMap), ...Object.keys(ogSettingsMap)]);

		for (const storeID of Array.from(combinedKeys)) {
			const settings = userSettingsMap[storeID];
			if (!settings) continue;

			const [newSettings, error] = await safeWrap<Partial<UserSettings>>(() => updateUserSettings(settings, storeID));
			if (error) {
				errors.push(error);
				continue;
			}
			newSettingMap[storeID] = newSettings ?? settings ?? {};
		}

		if (errors.length > 0) {
			utils.toaster?.addToast?.({ message: 'Encountered errors while saving settings', type: 'danger', placement: 'top' });
		}

		set({
			userSettingsMap: newSettingMap,
			ogSettingsMap: clone(newSettingMap),
			loading: false,
		});
		utils.toaster?.addToast?.({ message: 'Settings saved successfully', type: 'success', placement: 'top' });
	},

	// Will set the app settings of the currently selected store to the default app settings
	resetAppSettings: () => {
		get().setUserSettings((curr) => ({
			...curr,
			appSettings: {
				// App info is preserved
				appInfo: curr.appSettings?.appInfo,
			},
		}));
	},

	// Clear all changes/edits to the settings
	clearChanges() {
		const storeID = get().selectedStoreID;
		const userSettingsMap = clone(get().ogSettingsMap);
		const userSettings = userSettingsMap[storeID] ?? {};
		const globalSettings = userSettingsMap['-1'] ?? {};

		set({ loaded: false, userSettingsMap, userSettings, globalSettings });
	},

	getWithDefault<T = string>(paths: Paths<UserSettings>[], defaultValue: T) {
		const { selectedStoreID, userSettings, userSettingsMap } = get();
		const storeID = `${selectedStoreID ?? '-1'}`;

		// For global settings
		if (storeID === '-1') {
			for (const path of paths) {
				const value = getValue(userSettings, path);
				if (!isEmptyValue(value)) {
					return value ?? defaultValue;
				}
			}
			return defaultValue;
		}

		// Check store-specific settings first
		for (const path of paths) {
			const storeValue = getValue(userSettings, path);
			if (!isEmptyValue(storeValue)) {
				return storeValue ?? defaultValue;
			}
		}

		// Then check global settings
		const globalSettings = userSettingsMap['-1'];
		for (const path of paths) {
			const globalValue = getValue(globalSettings, path);
			if (!isEmptyValue(globalValue)) {
				return globalValue ?? defaultValue;
			}
		}

		return defaultValue;
	},
}));

export const isEmptyValue = (value: any) => {
	return value === undefined || value === null || value === '';
};
